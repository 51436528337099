import { media, pseudo } from '@glitz/core';
import { styled } from '@glitz/react';
import React from 'react';
import Breadcrumbs from '../Shared/Breadcrumb/Breadcrumb';
import HeroModule from '../Shared/Hero/HeroModule';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { theme } from '../Theme';
import RetailerListingPageModel from './RetailerListingPageModel.interface';
import ContentArea from '../Shared/ContentArea/ContentArea';

const RetailerListingPage = () => {
  const {
    retailerBlocks,
    teaserHeader,
    teaserImage,
    teaserLinks,
    teaserText,
    inEditMode,
  } = useCurrentPage<RetailerListingPageModel>();
  return (
    <Root>
      <BreadCrumbContainer>
        <Breadcrumbs />
      </BreadCrumbContainer>
      {teaserImage && (
        <HeroModule
          image={teaserImage}
          inEditMode={inEditMode}
          header={teaserHeader}
          summary={teaserText}
          links={teaserLinks}
        />
      )}
      <LowerContainer>
        <PageContainer>
          {retailerBlocks &&
            retailerBlocks.map((block, index) => {
              return (
                block.header && (
                  <Block key={index + block.header}>
                    <BlockHeader> {block.header} </BlockHeader>
                    {block.retailerItems.length > 0 && (
                      <List>
                        <ContentArea childItems={block.retailerItems} />
                      </List>
                    )}
                  </Block>
                )
              );
            })}
        </PageContainer>
      </LowerContainer>
    </Root>
  );
};

const LowerContainer = styled.div({
  backgroundColor: theme.white,
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { top: theme.spacing(16), bottom: theme.spacing(8) },
  }),
});

const Block = styled.div({
  display: 'flex',
  flexDirection: 'column',
  ...pseudo(':not(:last-child)', {
    marginBottom: theme.spacing(8),
  }),
});

const BlockHeader = styled.h3({
  font: { size: theme.epsilon, weight: theme.fontWeight.bold },
  color: theme.blueDark,
  padding: { xy: theme.spacing(8) },
  backgroundColor: theme.lightAqua,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.eta,
  }),
});

const List = styled.div({
  display: 'grid',
  gridGap: theme.spacing(16),
  gridTemplateColumns: '1fr 1fr 1fr',
  padding: { xy: theme.spacing(8) },
  ...media(theme.mediaQuery.mediaMaxMedium, {
    gridTemplateColumns: '1fr',
    padding: { y: theme.spacing(8), x: theme.none },
  }),
});

const BreadCrumbContainer = styled.div({
  ...media(theme.mediaQuery.mediaMaxLarge, {
    margin: { x: theme.spacing(4) },
  }),
});

const PageContainer = styled.div({
  width: '100%',
  maxWidth: theme.rowSectionContentMaxWidth,
  margin: {
    x: 'auto',
  },
  padding: {
    y: theme.none,
  },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    padding: { xy: theme.spacing(4) },
  }),
});

const Root = styled.div({
  color: theme.black,
  backgroundColor: theme.lightAqua,
});
export default RetailerListingPage;
